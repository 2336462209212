$blue: #fbbf24;
$time: 400ms;
$bagHeight: 20px;

@keyframes shift-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-70px);
  }
}

@keyframes shift-left-circle {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-70px);
  }
  100% {
    transform: translateX(-70px);
  }
}

@keyframes shift-left-mask {
  0% {
    height: 7px;
    transform: translateX(0) rotate(0);
  }
  50% {
    transform: translateX(0) rotate(180deg);
  }
  100% {
    transform: translateX(-40px) rotate(180deg);
  }
}

@mixin bag-inactive() {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $blue;
  transition: all $time cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@mixin bag-active() {
  width: 20px;
  height: 20px;
  top: 12px;
  left: 90px;
  animation: shift-left $time forwards;
  animation-delay: $time*3;
  transition-delay: $time;
}


.btn-cart {
  display: block;
  width: 150px;
  line-height: 30px;
  border: none;
  margin: 0 auto;
  background: none;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  

  
  /** Strap **/
  &:before {
    content: "";
    display: block;
    width: $bagHeight*.6;
    height: $bagHeight*.6;
    position: absolute;
    border: 2px solid $blue;
    transform: translateX(0);
    left: 94px;
    border-radius: 50%;
    top: 5px;
    box-sizing: border-box;
  }
  
  /** Bag Square **/
  &:after {
    @include bag-inactive();
  }
  
  &:focus {
    outline: none;
    
    /** Strap **/
    &:before {
      animation: shift-left-circle $time*2 forwards;
      animation-delay: $time*3;
    }

    /** Bag Square **/
    &:after {
      @include bag-active();
    }
    
    & > span {
      
      /** White Mask Over Strap **/
      &:before {
          animation: shift-left-mask $time*2 forwards;
  animation-delay: $time*2;
        height: 7px;
      }
      
      /** "ADDED" text **/
      &:after {
        transform: translate(-30%, 20%); 
        transition-delay: $time*4;
        opacity: 1;
      }
      
      /** "ADD TO CART" text **/
      span {
        opacity: 0;
        transform: translateY(20px);
      } 
    }
    
  }
  
  > span {
    position: relative;
    display: block;
    
    /** White Mask Over Circle **/
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 12px;
      height: 20px;
      background: white;
      top: 5px;
      left: 94px;
      animation-timing-function: linear;
      transform: translateX(0) rotate(0deg);
      transform-origin: center bottom;
    }
    
    /** "ADDED" text **/
    &:after {
      content: "ADDED";
      color: #fbbf24;
      position: absolute;
      z-index: 3;
      left: 50%;
      opacity: 0;
      transition: all $time cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transform: translate(-30%, 20px);
      transition-delay: 0;
    }
  }
  
  span span {
    display: inline-block;
    position: relative;
    z-index: 2;
    transition: all $time cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translateY(0px);
  }



@media screen and (max-width: 900px) {
  .btn-cart {
    width: 150px;
    font-size: 12px;

    &:before, &:after {
      left: calc(50% - 10px); // Centered
    }

    > span:before {
      left: calc(50% - 10px); // Centered
    }
  }
}

@media screen and (max-width: 600px) {
  .btn-cart {
    width: 10px;
    font-size: 10px;

  }
}

@media screen and (max-width: 400px) {
  .btn-cart {
    width: 100px;
    font-size: 8px;

  }
}

}