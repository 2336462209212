@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fff8f0
  
}

* {
  margin: 0;
  padding: 0;
  
}

@keyframes shine {
  0% {
    background-position: -200px;
  }
  100% {
    background-position: 90px;
  }
}

.shine {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.561) 50%, rgba(255, 255, 255, 0.1) 75%);
  background-size: 200% 100%;
  animation: shine 5s linear infinite;
  background-color: #eab308;
}

.shine-gold {
  background: linear-gradient(90deg, #FFD700, #FF8C00, #FFD700);
  background-size: 200% auto;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: shine 2s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}



